import { Component, inject } from '@angular/core'
import { SidebarComponent } from '../../components/sidebar/sidebar.component'
import { RouterOutlet } from '@angular/router'
import { NavbarComponent } from '../../components/navbar/navbar.component'
import { CommonModule } from '@angular/common'
import { BaseLayoutService } from '../../services/base-layout/base-layout.service'

@Component({
  selector: 'app-base',
  standalone: true,
  imports: [SidebarComponent, RouterOutlet, NavbarComponent, CommonModule],
  templateUrl: './base.component.html',
  styleUrl: './base.component.css',
})
export class BaseComponent {
  baseLayoutService = inject(BaseLayoutService)
}
