import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router'
import { Observable, of } from 'rxjs'
import { UserService } from '../../services/user/user.service'
import { inject } from '@angular/core'

export function hasRole(role: string): CanActivateFn {
  return (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> => {
    const userService = inject(UserService)
    const router = inject(Router)

    if (userService.roles.includes(role)) {
      return of(true)
    } else {
      return of(router.createUrlTree(['/403']))
    }
  }
}
