import { Routes } from '@angular/router'
import { isUserAuthenticated } from './shared/guards/auth/auth.guard'
import { BaseComponent } from './shared/layouts/base/base.component'
import { hasRole } from './shared/guards/role/role.guard'

export const routes: Routes = [
  {
    path: 'login',
    loadComponent: () =>
      import('./pages/login/login.component').then((m) => m.LoginComponent),
    title: 'Login',
  },
  {
    path: '',
    canActivate: [isUserAuthenticated()],
    component: BaseComponent,
    children: [
      {
        path: '',
        title: 'Dashboard',
        canActivate: [isUserAuthenticated()],
        loadComponent: () =>
          import('./pages/dashboard/dashboard.component').then(
            (m) => m.DashboardComponent
          ),
      },
      {
        path: 'bookings',
        canActivate: [isUserAuthenticated()],
        loadComponent: () =>
          import('./pages/bookings/bookings.component').then(
            (m) => m.BookingsComponent
          ),
        title: 'Bookings',
        data: {
          userType: 'user',
        }
      },
      {
        path: 'admin-bookings',
        canActivate: [isUserAuthenticated(), hasRole('administrator')],
        loadComponent: () =>
          import('./pages/bookings/bookings.component').then(
            (m) => m.BookingsComponent
          ),
        title: 'Admin Bookings',
        data: {
          userType: 'administrator',
        }
      },
      {
        path: 'fluent-crm',
        canActivate: [isUserAuthenticated(), hasRole('administrator')],
        loadComponent: () =>
          import('./pages/fluent-crm/fluent-crm.component').then(
            (m) => m.FluentCrmComponent
          ),
        title: 'Sync Fluent CRM',
      },
      {
        path: 'maps',
        canActivate: [isUserAuthenticated(), hasRole('administrator')],
        loadComponent: () =>
          import('./pages/maps/maps.component').then((m) => m.MapsComponent),
        title: 'Maps',
      },
    ],
  },
]
