import { AfterViewInit, Component, inject } from '@angular/core'
import feather from 'feather-icons'
import { BaseLayoutService } from '../../services/base-layout/base-layout.service'
import { CommonModule } from '@angular/common'

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.css',
})
export class NavbarComponent implements AfterViewInit {
  baseLayoutService = inject(BaseLayoutService)

  ngAfterViewInit(): void {
    feather.replace()
  }
}
