import { ApplicationConfig, importProvidersFrom } from '@angular/core'
import { provideRouter } from '@angular/router'
import { provideAnimations } from '@angular/platform-browser/animations'

import { routes } from './app.routes'
import { provideHttpClient, withInterceptors } from '@angular/common/http'
import { MessageService } from 'primeng/api'
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt'
import { AuthInterceptorService } from './shared/interceptors/auth.interceptor'
import { provideOAuthClient } from 'angular-oauth2-oidc'

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptors([AuthInterceptorService])),
    provideAnimations(),
    MessageService,
    JwtHelperService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    provideOAuthClient(),
  ],
}
