import { Injectable, OnInit, signal } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class WindowSizeService implements OnInit {
  isMobile = signal<boolean>(this.checkIfMobile())

  ngOnInit(): void {
    this.isMobile.set(this.checkIfMobile())
  }

  private checkIfMobile(): boolean {
    return window.innerWidth < 768
  }

  checkWindowSize() {
    this.isMobile.set(this.checkIfMobile())
  }
}
