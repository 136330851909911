import { AfterViewInit, Component, HostListener, inject } from '@angular/core'
import * as feather from 'feather-icons'
import { WindowSizeService } from '../../services/window-size/window-size.service'
import { BaseLayoutService } from '../../services/base-layout/base-layout.service'
import { CommonModule } from '@angular/common'
import { AuthService } from '../../services/auth/auth.service'
import { RouterLink } from '@angular/router'
import { UserService } from '../../services/user/user.service'

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.css',
})
export class SidebarComponent implements AfterViewInit {
  windowSizeService = inject(WindowSizeService)
  baseLayoutService = inject(BaseLayoutService)
  authService = inject(AuthService)
  userService = inject(UserService)

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowSizeService.checkWindowSize()
  }

  ngAfterViewInit(): void {
    feather.replace()
  }
}
