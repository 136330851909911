<div
  class="min-w-max w-md block h-dvh bg-primary py-10 md:sticky md:left-0 md:top-0"
  [ngClass]="{
    hidden:
      windowSizeService.isMobile() && baseLayoutService.isSidebarCollapsed()
  }"
>
  <div class="flex h-full flex-col justify-start">
    <div class="mb-24 flex items-center justify-between px-10">
      <img class="w-32" src="../../../assets/images/snaps-banner.webp" alt="" />
      <button class="md:hidden" (click)="baseLayoutService.toggleSidebar()">
        <i data-feather="x"></i>
      </button>
    </div>
    <div class="flex h-full flex-col justify-between">
      <ul
        class="daisy-menu space-y-5 p-0 text-lg text-white [&_li>*]:rounded-none"
      >
        <li>
          <a
            routerLink=""
            (click)="
              windowSizeService.isMobile()
                ? baseLayoutService.toggleSidebar()
                : null
            "
            >Dashboard</a
          >
        </li>
        <li>
          <a
            routerLink="/bookings"
            (click)="
              windowSizeService.isMobile()
                ? baseLayoutService.toggleSidebar()
                : null
            "
            >Bookings</a
          >
        </li>
        <li>
          <details close>
            <summary>Admin</summary>
            <ul>
              <li>
                <a
                  routerLink="/bookings"
                  (click)="
                    windowSizeService.isMobile()
                      ? baseLayoutService.toggleSidebar()
                      : null
                  "
                  >Bookings</a
                >
              </li>
              <li>
                <a
                  routerLink="/fluent-crm"
                  (click)="
                    windowSizeService.isMobile()
                      ? baseLayoutService.toggleSidebar()
                      : null
                  "
                  >Fluent CRM</a
                >
              </li>
              <li>
                <a
                  routerLink="/maps"
                  (click)="
                    windowSizeService.isMobile()
                      ? baseLayoutService.toggleSidebar()
                      : null
                  "
                  >Maps</a
                >
              </li>
            </ul>
          </details>
        </li>
      </ul>
      <button (click)="authService.logout()" class="px-10">
        <div class="flex items-center text-white">
          <i data-feather="log-out"></i>
          <p class="ml-2">Logout</p>
        </div>
      </button>
    </div>
  </div>
</div>
