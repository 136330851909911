import { Injectable, inject } from '@angular/core'
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http'
import { Observable, tap } from 'rxjs'
import { AuthService } from '../../shared/services/auth/auth.service'
import { environment } from '../../../environments/environment'

export const AuthInterceptorService: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService)

  // Retrieve your auth token from a service or local storage
  const authToken = authService.getToken().token

  // Clone the request to add the new header.
  if (authToken && !req.url.includes(environment.wp_url)) {
    req = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${authToken}`),
    })
  }

  return next(req).pipe(
    tap({
      error: (error) => {
        if (!error.url.includes(environment.hub_api_url)) return

        if ([401, 403].includes(error.status)) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          authService.logout()
        }
      },
    })
  )
}
