import { AuthService } from '../../../shared/services/auth/auth.service'
import { inject } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router'
import { CanActivateFn } from '@angular/router'
import { Observable, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { UserService } from '../../services/user/user.service'

export function isUserAuthenticated(): CanActivateFn {
  return (
    route: ActivatedRouteSnapshot,
    rState: RouterStateSnapshot
  ): Observable<boolean | UrlTree> => {
    const authService = inject(AuthService)
    const userService = inject(UserService)
    const router = inject(Router)

    const { code, state } = route.queryParams
    if (code) {
      return authService.getAuthorizationCode(code).pipe(
        switchMap((tokenResponse: any) => {
          const { access_token, expires_in, refresh_token } = tokenResponse
          authService.setToken({
            token: access_token,
            expiry: expires_in,
            refreshToken: refresh_token,
          })
          return userService.getUserData(access_token).pipe(
            map((userResponse: any) => {
              userService.setUserData(userResponse)

              let redirectTo = state ? [state] : []
              // Remove the query parameters
              router.navigate(redirectTo, {
                queryParams: { code: null },
                queryParamsHandling: 'merge',
              })
              return true
            }),
            catchError(() => {
              console.log(rState.url)
              authService.logout(rState.url)
              return of(router.createUrlTree(['/login']))
            })
          )
        }),
        catchError(() => {
          authService.logout(rState.url)
          return of(router.createUrlTree(['/login']))
        })
      )
    } else {
      console.log(rState.url)
      return authService.isAuthenticated().pipe(
        switchMap((isAuthenticated) => {
          if (isAuthenticated) {
            // Check if user roles is an empty array
            if (userService.roles.length === 0) {
              // If user roles is empty, initiate getting user data
              const token = authService.getToken().token
              if (!token) {
                return of(router.createUrlTree(['/login']))
              }
              return userService.getUserData(token).pipe(
                map((userResponse: any) => {
                  userService.setUserData(userResponse)
                  return true
                }),
                catchError(() => {
                  authService.logout(rState.url)
                  return of(router.createUrlTree(['/login']))
                })
              )
            } else {
              return of(true)
            }
          } else {
            authService.logout(rState.url)
            return of(router.createUrlTree(['/login']))
          }
        })
      )
    }
  }
}
